import {
  IGetAllPlatformsResponse,
  IGetAllSellersParams,
  IGetAllSellersResponse,
  GetListPlatformParams,
  GetListPlatformResponse,
} from 'interfaces';
import { requestServices } from 'services';
import { getResult } from 'helpers/request';
import configHelpers from 'helpers/config';

const { sellerClient, staffClient } = requestServices;
const { getCommonConfig } = configHelpers;
const { maxLoopCount } = getCommonConfig();

const getAllPlatforms = async (): Promise<IGetAllPlatformsResponse['platforms']> => {
  const MAX_LOOP_COUNT = parseInt(maxLoopCount) || 20;
  const MAX_PAGE_SIZE = 200;

  let currentPage = 1;
  let allPlatforms: IGetAllPlatformsResponse['platforms'] = [];

  let loopCount = 0; // hardcode to prevent loop too many times

  while (true) {
    if (loopCount >= MAX_LOOP_COUNT) {
      break;
    }

    const response = await sellerClient.get('/platforms', {
      params: {
        pageSize: MAX_PAGE_SIZE,
        page: currentPage,
      },
    });

    const result = getResult(response);

    allPlatforms = allPlatforms.concat(result.platforms);

    if (result.platforms.length < MAX_PAGE_SIZE) {
      break;
    }

    currentPage++;
    loopCount++;
  }

  return allPlatforms;
};

const getAllPlatformsWithBff = async (): Promise<IGetAllPlatformsResponse['platforms']> => {
  const MAX_LOOP_COUNT = parseInt(maxLoopCount) || 20;
  const MAX_PAGE_SIZE = 200;

  let currentPage = 1;
  let allPlatforms: IGetAllPlatformsResponse['platforms'] = [];

  let loopCount = 0; // hardcode to prevent loop too many times

  while (true) {
    if (loopCount >= MAX_LOOP_COUNT) {
      break;
    }

    const response = await staffClient.get('/api/v1/platforms', {
      params: {
        pageSize: MAX_PAGE_SIZE,
        page: currentPage,
      },
    });

    const result = getResult(response);

    allPlatforms = allPlatforms.concat(result.platforms);

    if (result.platforms.length < MAX_PAGE_SIZE) {
      break;
    }

    currentPage++;
    loopCount++;
  }

  return allPlatforms;
};

const getListPlatforms = async (
  params: GetListPlatformParams
): Promise<GetListPlatformResponse> => {
  return staffClient
    .get('/api/v1/platforms', {
      params,
    })
    .then(getResult);
};

const getAllSellers = (
  params: IGetAllSellersParams
): Promise<IGetAllSellersResponse> => {
  return sellerClient
    .get('/sellers/get-all', {
      params,
    })
    .then(getResult);
};

export default {
  getAllPlatforms,
  getAllPlatformsWithBff,
  getAllSellers,
  getListPlatforms,
};
