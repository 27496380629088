import { FileDoneOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  flagConstants,
  guideConstants,
  resourceConstants,
  roleConstants,
  surveyConstants,
} from 'constants/index';
import { ORDER_PATHS } from 'constants/paths';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { commonHelpers } from 'helpers';
import { lazy } from 'react';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const { baseErpUrl } = getCommonConfig();

const { STAFF_BFF } = appConstants;
const {
  ACCOUNTANT,
  ACCOUNTANT_READONLY,
  MARKETPLACE_ORDER_READONLY,
  SELLER_ORDER_READONLY,
  SYNC_UP_DATA,
  OM_BATCH_UPDATE,
} = roleConstants;
const {
  RETURN_REQUESTS,
  PENDING_SALES_ORDERS,
  WARRANTY_ACTIVATION,
} = resourceConstants;
const { READ } = actionConstants;
const { FLAG_KEYS, EXTENDED_FLAG_KEYS } = flagConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { USER_SURVEY_FOR_ORDER_LIST } = surveyConstants;

const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));

const orderRoutes = [
  // Parent menu
  {
    path: '/orders',
    name: t('Orders.Root'),
    icon: FileDoneOutlined,
    children: [
      '/orders/marketplace',
      '/orders/marketplace/search',
      '/orders/seller',
      '/orders/return-requests',
      '/orders/customer-quotations',
      '/orders/sync',
      '/orders/import-shipments-status',
      '/orders/samsung-warranty-activations',
    ],
  },
  // Sub menu
  // -------------------- Order List------------------------------
  {
    exact: true,
    path: '/orders/marketplace',
    name: t('Orders.Marketplace'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.Marketplace),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ORDER_FULFILLMENT.ORDER,
      userGuideCode: USER_GUIDE_CODE.ORDER_FULFILLMENT.ALL_ORDERS,
    },
    survey: {
      surveyLink: USER_SURVEY_FOR_ORDER_LIST,
      surveyText: 'GiveFeedback',
    },
  },
  {
    exact: true,
    path: '/orders/seller',
    name: t('Orders.Seller'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.Seller),
    permissions: [permission(STAFF_BFF, SELLER_ORDER_READONLY)],
    usePermissionPrefix: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ORDER_FULFILLMENT.ORDER,
      userGuideCode: USER_GUIDE_CODE.ORDER_FULFILLMENT.FULFILLED_BY_SELLER,
    },
    hidePlatformSwitchByEpic: true,
    survey: {
      surveyLink: USER_SURVEY_FOR_ORDER_LIST,
      surveyText: 'GiveFeedback',
    },
  },
  {
    exact: true,
    path: '/orders/marketplace/search',
    name: t('Orders.Search'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.MarketplaceSearch),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
    usePermissionPrefix: true,
    hideSellerSwitch: true,
    flagKey: EXTENDED_FLAG_KEYS.SPLIT_ORDER_BFF_LIST_FOR_PLATFORM.key,
    survey: {
      surveyLink: USER_SURVEY_FOR_ORDER_LIST,
      surveyText: 'GiveFeedback',
    },
  },
  // -------------------- Order Detail ------------------------------
  {
    exact: true,
    path: '/orders/marketplace/:id',
    name: t('Orders.Detail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.MarketplaceDetail),
    permissions: [
      permission(STAFF_BFF, MARKETPLACE_ORDER_READONLY),
      permission(STAFF_BFF, ACCOUNTANT),
      permission(STAFF_BFF, ACCOUNTANT_READONLY),
    ],
    usePermissionPrefix: true,
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/orders/seller/:sellerId/:orderId',
    name: t('Orders.Detail'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.SellerDetail),
    permissions: [permission(STAFF_BFF, SELLER_ORDER_READONLY)],
    usePermissionPrefix: true,
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  // --------------------------------------------------
  {
    exact: true,
    path: '/orders/return-requests',
    name: t('Orders.ReturnRequests'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.ReturnRequests),
    permissions: [permission(STAFF_BFF, RETURN_REQUESTS, READ)],
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ORDER_FULFILLMENT.ORDER,
      userGuideCode: USER_GUIDE_CODE.ORDER_FULFILLMENT.RETURN_REQUESTS,
    },
  },
  {
    exact: true,
    path: '/orders/customer-quotations',
    name: t('Orders.CustomerQuotations'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.CustomerQuotations),
    permissions: [permission(STAFF_BFF, PENDING_SALES_ORDERS, READ)],
    flagKey: FLAG_KEYS.MANAGE_QUOTATION,
  },
  {
    exact: true,
    path: '/orders/sync',
    name: t('Orders.SyncUpData'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.SyncUpData),
    permissions: [permission(STAFF_BFF, SYNC_UP_DATA)],
    flagKey: FLAG_KEYS.SYNC_UP_ORDER_DATA,
    usePermissionPrefix: true,
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
  {
    exact: true,
    path: '/orders/import-shipments-status',
    name: t('Orders.ImportShipmentsStatus'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.ImportShipmentsStatus),
    permissions: [permission(STAFF_BFF, OM_BATCH_UPDATE)],
    flagKey: FLAG_KEYS.BATCH_UPDATE_SHIPMENTS_STATUS,
    usePermissionPrefix: true,
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.ORDER_FULFILLMENT.ORDER,
      userGuideCode: USER_GUIDE_CODE.ORDER_FULFILLMENT.IMPORT_SHIPMENTS_STATUS,
    },
  },
  {
    exact: true,
    path: '/orders/samsung-warranty-activations',
    name: t('Orders.SamsungWarrantyActivationsList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, ORDER_PATHS.WarrantyActivation),
    permissions: [permission(STAFF_BFF, WARRANTY_ACTIVATION, READ)],
    usePermissionPrefix: true,
    hidePlatformSwitch: true,
    hideSellerSwitch: true,
  },
];

export default orderRoutes;
